// @flow
import React, { useState } from 'react'
import axios from 'axios'
import { useIntl } from 'gatsby-plugin-intl'
import messages from '../QuickstartPage.lang'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import classNames from 'classnames'
import Button from '../../../basic/Button'
import Checkbox from '../../../basic/Checkbox'
import Modal from '../../../basic/Modal'
import { Form, Input, Label, FormGroup } from '../../../basic/Forms'
import baseStyles from './QuickstartFeaturelistForm.scss'
import { FormattedMessageSanitized } from '../../../FormattedMessageSanitized'

type Props = {
  ...StyleProps,
  trigger?: Object,
  toggleLabel?: Object
}

const FORM_URL = 'https://getform.io/f/f611d7fa-4c88-46dd-9b13-fa853b5076b6'

const QuickstartFeaturelistForm = ({ styles, trigger, toggleLabel }: Props) => {
  const { formatMessage, locale } = useIntl()
  const [pageTitle, setPageTitle] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [finished, setFinished] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const setHiddenTitleField = () => {
    if (global.window && global.window.document) {
      setPageTitle(`${document.title} | ${locale.toUpperCase()}`)
    }
  }
  const setTextCustomMessage = e => {
    e.target.setCustomValidity(formatMessage(messages.requiredTextInput))
  }
  const setCheckboxCustomMessage = e => {
    e.target.setCustomValidity(formatMessage(messages.requiredCheckbox))
  }
  const clearValidityMessage = e => {
    e.target.setCustomValidity('')
  }
  const validateForm = e => {
    if (global.window && global.window.document) {
      if (e.target.type && e.target.type === 'checkbox') {
        const requiredCheckBox = e.target
        if (requiredCheckBox) {
          requiredCheckBox.oninvalid = e => {
            setCheckboxCustomMessage(e)
          }
          requiredCheckBox.onchange = e => {
            clearValidityMessage(e)
          }
        }
      }
    }
    setSubmitted(true)
  }
  const submitForm = e => {
    e.preventDefault()
    setSubmitting(true)
    const formData = new FormData(e.target)
    axios({
      method: 'post',
      url: FORM_URL,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(() => {
        setFinished(true)
      })
      .catch(() => {
        setFinished(true)
      })
  }
  return (
    <div className={styles.root}>
      {trigger ? (
        <span onClick={() => setModalVisible(true)}>{trigger}</span>
      ) : (
        <Button
          styles={styles.btn}
          tag="span"
          color="accent"
          iconRight="icon-arrow_03-right-medium"
          onClick={() => setModalVisible(true)}
        >
          {toggleLabel ? toggleLabel : formatMessage(messages.teaserBtn)}
        </Button>
      )}

      <Modal
        visible={modalVisible}
        styles={styles.modal}
        shouldCloseOnOverlayClick
        onClose={() => setModalVisible(false)}
      >
        <div className={styles.modalContent}>
          {!finished ? (
            <>
              <h3 className={styles.modalTitle}>
                <FormattedMessageSanitized {...messages.featurelistModaltitle} />
              </h3>
              <div className={classNames(styles.formRoot, submitted && styles.isValidated)}>
                <Form
                  className={styles.form}
                  action={FORM_URL}
                  method="POST"
                  onInvalid={validateForm}
                  onSubmit={submitForm}
                >
                  <FormGroup className={styles.group}>
                    <Label className={styles.label}>{formatMessage(messages.labelName)}</Label>
                    <Input
                      type="text"
                      placeholder={formatMessage(messages.placeholderName)}
                      name="name"
                      required
                      styles={styles.input}
                      onInvalid={setTextCustomMessage}
                      onInput={clearValidityMessage}
                    />
                  </FormGroup>
                  <FormGroup className={styles.group}>
                    <Label className={styles.label}>{formatMessage(messages.labelCompany)}</Label>
                    <Input
                      type="text"
                      placeholder={formatMessage(messages.placeholderCompany)}
                      name="company"
                      required
                      styles={styles.input}
                      onInvalid={setTextCustomMessage}
                      onInput={clearValidityMessage}
                    />
                  </FormGroup>
                  <FormGroup className={styles.group}>
                    <Label className={styles.label}>{formatMessage(messages.labelMail)}</Label>
                    <Input
                      type="email"
                      placeholder={formatMessage(messages.placeholderMail)}
                      name="email"
                      required
                      styles={styles.input}
                      onInvalid={setTextCustomMessage}
                      onInput={clearValidityMessage}
                    />
                  </FormGroup>
                  <FormGroup className={styles.group}>
                    <Label className={styles.label}>{formatMessage(messages.labelFreetext)}</Label>
                    <Input
                      type="text"
                      placeholder={formatMessage(messages.placeholderFreetext)}
                      name="message"
                      styles={styles.input}
                      onInvalid={setTextCustomMessage}
                      onInput={clearValidityMessage}
                    />
                  </FormGroup>
                  <Checkbox required className={styles.checkbox} onChange={setHiddenTitleField}>
                    <div
                      className={styles.checkboxText}
                      dangerouslySetInnerHTML={{ __html: formatMessage(messages.checkboxText)
                          .replaceAll("'", '') }}
                    />
                  </Checkbox>
                  <Input type="hidden" name="page" value={pageTitle} />
                  <Button
                    type="submit"
                    styles={styles.modalBtn}
                    color="accent"
                    iconRight="icon-arrow_03-right-medium"
                    disabled={submitting}
                  >
                    {formatMessage(messages.featurelistModalLabelButton)}
                  </Button>
                </Form>
              </div>
            </>
          ) : (
            <>
              <h3 className={styles.modalTitleSuccess}>
                <FormattedMessageSanitized {...messages.featurelistSuccessModalTitle} />
              </h3>
              <div className={styles.modalsubtitle}>{formatMessage(messages.featurelistSuccessModalText)}</div>
              <div className={styles.btnSuccessWrap}>
                <a
                  href="https://drive.google.com/file/d/1ZDiqHC86HRjOPtCkSfIRE54rKaR2Usfb/view?usp=sharing"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button styles={styles.downloadBtn} tag="span" color="accent" iconRight="icon-arrow_03-right-medium">
                    {formatMessage(messages.featurelistModalDownloadBtn)}
                  </Button>
                </a>
                <Button
                  styles={styles.closeBtn}
                  tag="span"
                  color="primary"
                  outline
                  iconRight="icon-arrow_03-right-medium"
                  onClick={() => setModalVisible(false)}
                >
                  {formatMessage(messages.successModalBtn)}
                </Button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(
  QuickstartFeaturelistForm
)
