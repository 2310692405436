// @flow
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useIntl } from 'gatsby-plugin-intl'
import messages from './QuickstartPage.lang'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import classNames from 'classnames'
// components
import ProductTeaser2 from '../ProductTeaser2'
import ValueBox from '../ValueBox'
import VideoModal from '../VideoModal'
import Products from '../../Products'
import QuickstartInfographic from '../InfographicTeasers/QuickstartInfographic'
import QuickstartDemoTeaser from './QuickstartDemoTeaser'
import QuickstartDemoForm from './QuickstartDemoForm'
import QuickstartFeaturelistForm from './QuickstartFeaturelistForm'
import ScrollAnimation from 'react-animate-on-scroll'
import CaseTeaser from '../../CaseStudy/CaseTeaser'
import CaseTeasers from '../../CaseStudy/CaseTeasers'
import ListItem from '../../basic/ListItem'
import CaseLogosSlider from '../../CaseStudy/CaseLogosSlider'
import { Container, Row, Col } from '../../basic/Grid'
import Scrollspy from 'react-scrollspy'
import Headroom from 'react-headroom'
import Section from '../../basic/Section'
import baseStyles from './QuickstartPage.scss'

// images
/* eslint-disable max-len */
import quickstartLogo from '../../../images/logo/quickstart.svg'

const bgDesktop = (
  <StaticImage src="../../../images/products/quickstart/bg.jpg" alt="" objectFit="cover" className="imageCover" />
)
const teaser1Desktop = (
  <StaticImage
    src="../../../images/products/tsbo/tsbo-teaser2-desktop.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)

const benefitImg01 = (
  <StaticImage
    src="../../../images/products/quickstart/quickstart-the-quickstart.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const featureListImg = (
  <StaticImage
    src="../../../images/products/quickstart/quickstart-featurelist.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const featurelistImg01 = (
  <StaticImage
    src="../../../images/products/quickstart/quickstart-featurelist-teaser01.jpg"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const featurelistImg02 = (
  <StaticImage
    src="../../../images/products/quickstart/quickstart-featurelist-teaser02.jpg"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const featurelistImg03 = (
  <StaticImage
    src="../../../images/products/quickstart/quickstart-featurelist-teaser03.jpg"
    alt=""
    objectFit="contain"
    className="image"
  />
)

// references logos
const fiveStar = (
  <StaticImage
    src="../../../images/casestudy/lufthansaholidays/wls/5star.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const condor = (
  <StaticImage
    src="../../../images/casestudy/lufthansaholidays/wls/condor.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const exclusive = (
  <StaticImage
    src="../../../images/casestudy/lufthansaholidays/wls/exclusive.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const globista = (
  <StaticImage
    src="../../../images/casestudy/lufthansaholidays/wls/globista.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const guru = (
  <StaticImage
    src="../../../images/casestudy/lufthansaholidays/wls/guru.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const hlx = (
  <StaticImage
    src="../../../images/casestudy/lufthansaholidays/wls/hlx.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const lhh = (
  <StaticImage
    src="../../../images/casestudy/lufthansaholidays/wls/lhh.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const nrj = (
  <StaticImage
    src="../../../images/casestudy/lufthansaholidays/wls/nrj.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const piratten = (
  <StaticImage
    src="../../../images/casestudy/lufthansaholidays/wls/piratten.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const se = (
  <StaticImage
    src="../../../images/casestudy/lufthansaholidays/wls/se.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const swiss = (
  <StaticImage
    src="../../../images/casestudy/lufthansaholidays/wls/swiss.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const tchibo = (
  <StaticImage
    src="../../../images/casestudy/lufthansaholidays/wls/tchibo.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const travelzoo = (
  <StaticImage
    src="../../../images/casestudy/lufthansaholidays/wls/travelzoo.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const kuoni = (
  <StaticImage
    src="../../../images/casestudy/dertouristik/wls/kuoni.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const lastminute = (
  <StaticImage
    src="../../../images/casestudy/dertouristik/wls/lastminute.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const helvetictours = (
  <StaticImage
    src="../../../images/casestudy/dertouristik/wls/helvetictours.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const alltours = (
  <StaticImage src="../../../images/clientlogos/act-alltours.png" alt="" objectFit="contain" className="image" />
)
/* eslint-enable max-len */

type Props = {
  ...StyleProps
}

const productValues1 = ['tsboquickstartValue1a', 'tsboquickstartValue1b', 'tsboquickstartValue1c']

const productValues2 = [
  'tsboquickstartValue2a',
  'tsboquickstartValue2b',
  'tsboquickstartValue2c',
  'tsboquickstartValue2d'
]

const productValues3 = ['tsboquickstartValue3a', 'tsboquickstartValue3b']
/*
const descriptionValues = [
  'tsboDescription1', 'tsboDescription2', 'tsboDescription3', 'tsboDescription4', 'tsboDescription5'
]*/

const featureOverview = [
  { label: messages.feature01, icon: 'flexible' },
  { label: messages.feature02, icon: 'star-filled' },
  { label: messages.feature03, icon: 'widgets' },
  { label: messages.feature04, icon: 'cloud-success' },
  { label: messages.feature05, icon: 'important-devices' },
  { label: messages.feature06, icon: 'palette' },
  { label: messages.feature07, icon: 'globe' },
  { label: messages.feature08, icon: 'google' },
  { label: messages.feature09, icon: 'shoppingcart' }
]

const moreFeatureOverview = [
  { label: messages.moreFeature01, icon: 'style' },
  { label: messages.moreFeature02, icon: 'shippingcart-add' },
  { label: messages.moreFeature03, icon: 'edit' },
  { label: messages.moreFeature04, icon: 'devices' },
  { label: messages.moreFeature05, icon: 'map' },
  { label: messages.moreFeature06, icon: 'offer' },
  { label: messages.moreFeature07, icon: 'cloud-circle' },
  { label: messages.moreFeature08, icon: 'cog' },
  { label: messages.moreFeature09, icon: 'translate' }
]

const QuickstartPage = ({ styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      <Headroom disableInlineStyles className={styles.navScrollContainer}>
        <Container className={styles.navContainer} fluid>
          <Scrollspy
            items={['overview', 'benefits', 'platform', 'features', 'references', 'video', 'demo']}
            currentClassName={styles.isCurrent}
            className={styles.nav}
            offset={-150}
          >
            <li className={styles.navItem}>
              <a className={styles.navItemLink} href="#overview">
                {formatMessage(messages.NavOverview)}
              </a>
            </li>
            <li className={styles.navItem}>
              <a className={styles.navItemLink} href="#benefits">
                {formatMessage(messages.NavBenefits)}
              </a>
            </li>
            <li className={styles.navItem}>
              <a className={styles.navItemLink} href="#platform">
                {formatMessage(messages.NavPlatform)}
              </a>
            </li>
            <li className={styles.navItem}>
              <a className={styles.navItemLink} href="#features">
                {formatMessage(messages.NavFeatures)}
              </a>
            </li>
            <li className={styles.navItem}>
              <a className={styles.navItemLink} href="#references">
                {formatMessage(messages.NavReferences)}
              </a>
            </li>
            <li className={classNames(styles.navItem, styles.navItemVideo)}>
              <VideoModal
                styles={styles.videoButton}
                trigger={<span className={styles.navItemLink}>{formatMessage(messages.NavVideo)}</span>}
              />
            </li>
            <li className={classNames(styles.navItem, styles.navItemDemo)}>
              <QuickstartDemoForm
                styles={styles.navItemDemoButton}
                trigger={
                  <span className={classNames(styles.navItemLink, styles.navItemLinkDemo)}>
                    {formatMessage(messages.NavDemo)}
                  </span>
                }
              />
            </li>
          </Scrollspy>
        </Container>
      </Headroom>
      <div id="overview" className={styles.ancor}>
        <ProductTeaser2
          styles={styles.heroTeaser}
          logo={quickstartLogo}
          backBtn={formatMessage(messages.backBtn)}
          teaserText={formatMessage(messages.quickstartTeaserText)}
          btnText={formatMessage(messages.btnText)}
          imageDesktop={teaser1Desktop}
          bgImageDesktop={bgDesktop}
          hiddenPageTitle="QuickStart"
        >
          <div className={styles.heroTeaserButtons}>
            <QuickstartDemoForm styles={styles.heroTeaserButton} />
            <VideoModal styles={styles.heroTeaserVideoButton} />
          </div>
        </ProductTeaser2>
      </div>
      <div id="benefits" className={styles.ancor}>
        <ScrollAnimation animateIn="fadeIn" animateOnce>
          <Section styles={styles.featureListsSection}>
            <Row styles={styles.featuresRow}>
              <Col styles={styles.featuresCol} lg="4">
                <ListItem
                  title={formatMessage(messages.featurelist01Title)}
                  list={[
                    formatMessage(messages.featurelist01listItem01),
                    formatMessage(messages.featurelist01listItem02),
                    formatMessage(messages.featurelist01listItem03)
                  ]}
                  img={featurelistImg01}
                  styles={styles.featurelist}
                />
              </Col>
              <Col styles={styles.featuresCol} lg="4">
                <ListItem
                  title={formatMessage(messages.featurelist02Title)}
                  list={[
                    formatMessage(messages.featurelist02listItem01),
                    formatMessage(messages.featurelist02listItem02),
                    formatMessage(messages.featurelist02listItem03)
                  ]}
                  img={featurelistImg02}
                  styles={styles.featurelist}
                  footnote={formatMessage(messages.featurelist02Footnote)}
                />
              </Col>
              <Col styles={styles.featuresCol} lg="4">
                <ListItem
                  title={formatMessage(messages.featurelist03Title)}
                  list={[
                    formatMessage(messages.featurelist03listItem01),
                    formatMessage(messages.featurelist03listItem02),
                    formatMessage(messages.featurelist03listItem03)
                  ]}
                  img={featurelistImg03}
                  styles={styles.featurelist}
                />
              </Col>
            </Row>
            <QuickstartDemoForm
              styles={styles.featuresDemoButton}
              toggleLabel={formatMessage(messages.featurelistButton)}
            />
          </Section>
        </ScrollAnimation>
      </div>
      <div id="platform" className={styles.ancor}>
        <QuickstartInfographic />
        <ScrollAnimation animateIn="fadeInUp" animateOnce>
          <CaseTeaser
            title={messages.theQuickstartTitle}
            richText={messages.theQuickstartDescription}
            img={benefitImg01}
            styles={styles.benefit01}
            imgWide
          >
            <QuickstartDemoForm styles={styles.theQuickstartButton} />
          </CaseTeaser>
        </ScrollAnimation>
      </div>
      <div id="features" className={styles.ancor}>
        <ValueBox
          styles={styles.values}
          productValues1={productValues1}
          productValues2={productValues2}
          productValues3={productValues3}
          icon1="icon-complex"
          icon2="icon-design"
          icon3="icon-strong"
          color1="primary"
          color2="primary-light"
          color3="primary-lighter"
          title1={formatMessage(messages.quickstartValueBoxTitle1)}
          title2={formatMessage(messages.quickstartValueBoxTitle2)}
          title3={formatMessage(messages.quickstartValueBoxTitle3)}
        />
        <ScrollAnimation animateIn="fadeInUp" animateOnce>
          <Section
            styles={styles.featureOverviewSection}
            title={formatMessage(messages.featuresOverviewTitle)}
          >
            <Row className={styles.featuresOverviewRow}>
              {featureOverview.map((feature, i) => {
                return (
                  <Col className={styles.featuresCol} sm="6" md="4" key={'feature' + i}>
                    <div className={styles.featuresOverviewItem}>
                      <span className={classNames(styles.featuresOverviewItemIcon, 'icon-' + feature.icon)} />
                      <span className={styles.featuresOverviewItemLabel}>{formatMessage(feature.label)}</span>
                    </div>
                  </Col>
                )
              })}
              <p className={styles.featureOverviewFootnote}>{formatMessage(messages.featuresOverviewFootnote)}</p>
            </Row>
          </Section>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" animateOnce>
          <Section
            styles={styles.moreFeatureOverviewSection}
            description={formatMessage(messages.moreFeaturesOverviewTitle)}
          >
            <Row className={styles.featuresOverviewRow}>
              {moreFeatureOverview.map((feature, i) => {
                return (
                  <Col className={styles.featuresCol} sm="6" md="4" key={'feature' + i}>
                    <div className={styles.featuresOverviewItem}>
                      <div className={classNames(styles.featuresOverviewItemIcon, 'icon-' + feature.icon)} />
                      <span
                        className={styles.featuresOverviewItemLabel}
                        dangerouslySetInnerHTML={{ __html: formatMessage(feature.label) }}
                      />
                    </div>
                  </Col>
                )
              })}
            </Row>
          </Section>
        </ScrollAnimation>
      </div>
      <div id="demo" className={styles.ancor}>
        <ScrollAnimation animateIn="fadeIn" animateOnce>
          <QuickstartDemoTeaser styles={styles.QuickstartDemoTeaser} />
        </ScrollAnimation>
      </div>
      <ScrollAnimation animateIn="fadeInUp" animateOnce>
        <CaseTeaser
          title={messages.featureListTitle}
          richText={messages.featureListDescription}
          img={featureListImg}
          styles={styles.featureList}
          imgWide
        >
          <QuickstartFeaturelistForm
            className={styles.downloadButton}
            toggleLabel={formatMessage(messages.featureListDownloadBtn)}
          />
        </CaseTeaser>
      </ScrollAnimation>
      <div id="references" className={styles.ancor}>
        <ScrollAnimation animateIn="fadeIn" animateOnce>
          <CaseLogosSlider
            title={messages.CaseLogosTitle}
            subtitle={messages.CaseLogosSubtitle}
            list={[
              { logo: alltours },
              { logo: swiss },
              { logo: lhh },
              { logo: travelzoo },
              { logo: kuoni },
              { logo: hlx },
              { logo: piratten },
              { logo: lastminute },
              { logo: helvetictours },
              { logo: se },
              { logo: condor },
              { logo: exclusive },
              { logo: globista },
              { logo: guru },
              { logo: nrj },
              { logo: tchibo },
              { logo: fiveStar }
            ]}
            styles={styles.logosSlider}
          />
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" animateOnce>
          <CaseTeasers list={['dertouristik', 'lhh', 'etravel', 'aldiana', 'weekend', 'bedfinder']} allButton />
        </ScrollAnimation>
      </div>
      <Products className={styles.products} type="quickstart" />
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(QuickstartPage)
