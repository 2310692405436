// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import messages from './ValueBoxItem.lang'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import cn from 'classnames'
import baseStyles from './ValueBoxItem.scss'

type Props = {
  ...StyleProps,
  values: any,
  title: string,
  icon: string,
  color: string
}

const ValueBoxItem = ({ styles, values, color, title, icon }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.container}>
      <span className={cn(styles.iconTile, icon, styles[color])} />
      <span className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
      <div className={styles.list}>
        {values.map(item => (
          <div className={styles.listItem} key={item}>
            <span className={cn(styles.icon, 'icon-success')} />
            <span className={styles.listText}>{formatMessage(messages[item])}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(ValueBoxItem)
