// @flow

import React from 'react'
import classNames from 'classnames'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { Container } from '../../basic/Grid'
import baseStyles from './Section.scss'

type Props = {
  ...StyleProps,
  title?: Object,
  subtitle?: Object,
  children?: Object,
  align?: string,
  id?: string,
  noPadding?: boolean,
  description?: Object,
}

const Section = ({
  styles, title, subtitle, children, align, noPadding, description, id
}: Props) => (
  <div id={id} className={classNames(styles.root, align ? styles['is' + align] : styles['isCentered'])}>
    <Container className={classNames(styles.container, noPadding && styles.hasNoPadding)} fluid>
      <div className={classNames(styles.titles, subtitle && styles.hasSubtitle)}>
        {title &&
          <h3 className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
        }
        {subtitle &&
          <h3 className={styles.subtitle} dangerouslySetInnerHTML={{ __html: subtitle }} />
        }
        {description &&
          <p className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
        }
      </div>
      {children &&
        <div className={styles.children}>{children}</div>
      }
    </Container>
  </div>
)

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(Section)
