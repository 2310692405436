// @flow
import React, { useState } from 'react'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import Modal from '../../basic/Modal'
import Button from '../../basic/Button'
import { useIntl } from 'gatsby-plugin-intl'

import messages from './VideoModal.lang'
import baseStyles from './VideoModal.scss'

type Props = {
  ...StyleProps,
  title?: string,
  videoUrl?: string,
  trigger?: Object,
  triggerLabel?: Object
}

const VideoModal = ({ videoUrl, title, styles, trigger, triggerLabel }: Props) => {
  const { formatMessage } = useIntl()
  const [modalVisible, setModalVisible] = useState(false)

  return (
    <div className={styles.root}>
      {trigger ? (
        <span onClick={() => setModalVisible(true)}>{trigger}</span>
      ) : (
        <Button
          styles={styles.btn}
          tag="button"
          color="primary"
          iconRight="icon-play"
          onClick={() => setModalVisible(true)}
        >
          {triggerLabel ? triggerLabel : formatMessage(messages.playVideo)}
        </Button>
      )}
      <Modal
        visible={modalVisible}
        styles={styles.modal}
        shouldCloseOnOverlayClick
        onClose={() => setModalVisible(false)}
        title={title && <div className={styles.title}>{title}</div>}
      >
        <div className={styles.container}>
          <div className={styles.outerWrapper}>
            <div className="video_wrapper video_wrapper_full js-videoWrapper">
              <iframe
                className="videoIframe js-videoIframe"
                frameBorder="0"
                allowTransparency="true"
                allowFullScreen
                allow="autoplay"
                src={videoUrl ? videoUrl : 'https://www.youtube.com/embed/IQJvQlYA0W8'}
              ></iframe>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(VideoModal)
