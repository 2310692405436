// @flow

import React from 'react'
import classNames from 'classnames'
import { Link } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import logo1 from '../../../../images/clientlogos/act-alltours.png'
import baseStyles from './ClientLogo.scss'

type Props = {
  ...StyleProps,
  logo: Object,
  link?: string,
  linkToBase?: boolean
}

const defaultProps = {
  styles: {},
  logo: logo1
}

const ClientLogo = ({ styles, logo, link, linkToBase }: Props) => (
  <div className={styles.root}>
    { link ?
      <a href={link} target="_blank" rel="noopener noreferrer" aria-label="Link to client website"
        className={classNames(styles.logoWrap, styles.link)}
      >
        {logo}
      </a> : linkToBase ?
        <div className={classNames(styles.logoWrap, styles.link)}>
          <Link to="/references/">
            {logo}
          </Link>
        </div> :
        <div className={styles.logoWrap}>
          {logo}
        </div>
    }
  </div>
)

ClientLogo.defaultProps = defaultProps

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(ClientLogo)
