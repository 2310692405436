// @flow

import React from 'react'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { Container } from '../../../components/basic/Grid'
import cn from 'classnames'
import { Link } from 'gatsby-plugin-intl'
import baseStyles from './ProductTeaser2.scss'
import ScrollAnimation from 'react-animate-on-scroll'
// FIXME: causing build to fail
// import { Link as AnchorLink } from 'react-scroll'
// import Button from '../../../components/basic/Button'

type Props = {
  ...StyleProps,
  logo: string,
  hiddenPageTitle?: string,
  teaserText: string,
  backBtn: string,
  btnText: string,
  imageDesktop: Object,
  bgImageDesktop: Object,
  children?: Object,
}

const defaultProps = {
  styles: {}
}

const ProductTeaser = ({
  styles, backBtn, children, teaserText, logo, imageDesktop, bgImageDesktop, hiddenPageTitle }: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.imageContainer}>
        {bgImageDesktop}
      </div>
      <div className={styles.content}>
        <Container className={styles.container} fluid>
          <Link className={styles.backBtnLink} to="/products/">
            <div className={styles.backBtn}>
              <span className={cn(styles.backIcon, 'icon-arrow_03-left-medium')} />
              <span className={styles.backBtnLabel}>
                {backBtn}
              </span>
            </div>
          </Link>
          <div className={styles.teaserContent}>
            <div className={styles.teaserInfo}>
              <ScrollAnimation animateIn="fadeInLeft" animateOnce delay={100}>
                {hiddenPageTitle && <h1 className={styles.hiddenPageTitle}>{hiddenPageTitle}</h1>}
                <img className={styles.teaserLogo} src={logo} alt="logo"/>
                <p className={styles.teaserText}>
                  {teaserText}
                </p>
                {children}
                {/* FIXME: causing build to fail
                <AnchorLink to="anchor" smooth={true} duration={500}>
                  <Button
                    styles={styles.btn}
                    tag="span"
                    block
                    color="accent"
                    iconRight="icon-arrow_03-right-medium"
                  >
                    {btnText}
                  </Button>
                </AnchorLink>
                */}
              </ScrollAnimation>
            </div>
            <ScrollAnimation className={styles.teaserImageContainer} animateIn="fadeIn" animateOnce delay={200}>
              <div className={styles.teaserImage}>
                {imageDesktop}
              </div>
            </ScrollAnimation>
          </div>
        </Container>
      </div>
    </div>
  )
}

ProductTeaser.defaultProps = defaultProps

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(ProductTeaser)
