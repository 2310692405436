// @flow
// base
// import { ArcherContainer, ArcherElement } from 'react-archer'
import React from 'react'
import cn from 'classnames'
import { useIntl } from 'gatsby-plugin-intl'
import messages from './QuickstartInfographic.lang'
import ScrollAnimation from 'react-animate-on-scroll'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
// components
import { Container, Row, Col } from '../../../basic/Grid'
import IconItem from '../../IconItem'
// assets
import peakworkSource from '../../../../images/products/source-peakwork.svg'
import peakworkLogo from '../../../../images/products/logo-peakwork.svg'
import amadeusSource from '../../../../images/products/source-amadeus.svg'
import amadeusLogo from '../../../../images/products/logo-amadeus.svg'
import bewotecSource from '../../../../images/products/source-bewotec.svg'
import bewotecLogo from '../../../../images/products/logo-bewotec.svg'
import sabreSource from '../../../../images/products/source-sabre-synxis.svg'
import sabreLogo from '../../../../images/products/logo-sabre-synxis.svg'
// import gygSource from '../../../../images/products/source-getyourguide.svg'
// import gygLogo from '../../../../images/products/logo-getyourguide.svg'
import productDesktop from '../../../../images/products/tsbo/tsbo-teaser4-desktop.png'
import productDesktop2x from '../../../../images/products/tsbo/tsbo-teaser4-desktop@2x.png'

// styling
import baseStyles from './QuickstartInfographic.scss'
import { FormattedMessageSanitized } from '../../../FormattedMessageSanitized'

type Props = {
  ...StyleProps,
  title?: Object,
  contentText?: Object
}

const QuickstartInfographic = ({ styles, title, contentText }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      <Container className={styles.container} fluid>
        <h2 className={styles.title}>{title ? title : <FormattedMessageSanitized {...messages.title} />}</h2>
        <Row styles={styles.row}>
          <Col className={styles.colLeft} md="6" xxl="5">
            <p className={styles.contentText}>
              {contentText ? contentText : <FormattedMessageSanitized {...messages.contentText} />}
            </p>
            <ScrollAnimation animateIn="fadeInUp" animateOnce delay={200}>
              <h3 className={styles.subtitle}>{formatMessage(messages.subtitle)}</h3>
              <div className={styles.sourceIconWrapper}>
                <div className={styles.sourceItem}>
                  <span className={cn(styles.sourceIcon, styles.sourceIcon)}>
                    <img className={styles.sourceIconImg} src={peakworkSource} alt="image pearkwork source" />
                  </span>
                  <span className={styles.sourceLogo}>
                    <img className={styles.sourceLogoImg} src={peakworkLogo} alt="peakwork logo" />
                  </span>
                </div>
                <div className={styles.sourceItem}>
                  <span className={cn(styles.sourceIcon, styles.amadeusIcon)}>
                    <img className={styles.sourceIconImg} src={amadeusSource} alt="image amadeus source" />
                  </span>
                  <span className={styles.sourceLogo}>
                    <img className={styles.sourceLogoImg} src={amadeusLogo} alt="amadeus logo" />
                  </span>
                </div>
                {/*
                <div className={styles.sourceItem}>
                  <span className={cn(styles.sourceIcon, styles.gygIcon)}>
                    <img className={styles.sourceIconImg} src={gygSource} alt="imagegyg source"/>
                  </span>
                  <span className={styles.sourceLogo}>
                    <img className={styles.sourceLogoImg} src={gygLogo} alt="gyg logo"/>
                  </span>
                </div>*/}
                <div className={styles.sourceItem}>
                  <span className={cn(styles.sourceIcon, styles.bewotecIcon)}>
                    <img className={styles.sourceIconImg} src={bewotecSource} alt="bewotec source" />
                  </span>
                  <span className={styles.sourceLogo}>
                    <img className={styles.sourceLogoImg} src={bewotecLogo} alt="bewotec logo" />
                  </span>
                </div>
                <div className={styles.sourceItem}>
                  <span className={cn(styles.sourceIcon, styles.sabreIcon)}>
                    <img className={styles.sourceIconImg} src={sabreSource} alt="Sabre SynXis source" />
                  </span>
                  <span className={styles.sourceLogo}>
                    <img className={styles.sourceLogoImg} src={sabreLogo} alt="Sabre SynXis logo" />
                  </span>
                </div>
                <div className={styles.sourceItem}>
                  <IconItem
                    className={styles.anyIcon}
                    label={formatMessage(messages.icon4Label)}
                    color="tertiary"
                    icon="icon-infinity"
                  />
                </div>
              </div>
            </ScrollAnimation>
          </Col>
          <Col styles={styles.colRight} md="6" xxl="7">
            <ScrollAnimation animateIn="fadeInRight" animateOnce delay={300}>
              <picture className={styles.productImg}>
                <source media={'(min-width: 1440px)'} srcSet={`${productDesktop2x} 2x`} />
                <source media={'(min-width: 768px)'} srcSet={`${productDesktop} 1x, ${productDesktop2x} 2x`} />
                <source media={'(max-width: 767px)'} srcSet={`${productDesktop} 1x, ${productDesktop2x} 2x`} />
                <img className={styles.productImg} src={productDesktop} alt="Quickstart product page" />
              </picture>
            </ScrollAnimation>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(QuickstartInfographic)
