// @flow
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import messages from '../QuickstartPage.lang'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { Container, Row, Col } from '../../../basic/Grid'
import classNames from 'classnames'
import baseStyles from './QuickstartDemoTeaser.scss'
import QuickstartDemoForm from '../QuickstartDemoForm'
import { FormattedMessageSanitized } from '../../../FormattedMessageSanitized'

const QSImg = (
  <StaticImage src="../../../../images/products/quickstart_mockup.jpg" alt="" objectFit="cover" className="image" />
)

type Props = {
  ...StyleProps
}

const QuickstartDemoTeaser = ({ styles }: Props) => {
  return (
    <div className={styles.root}>
      <Container className={styles.container} fluid>
        <Row className={styles.row}>
          <Col xs="12" md="6" className={classNames(styles.col, styles.colImg)}>
            <div className={styles.imageWrap}>
              <div className={styles.image}>{QSImg}</div>
            </div>
          </Col>
          <Col xs="12" md="6" className={classNames(styles.col, styles.colContent)}>
            <div className={styles.contentTeaser}>
              <h2 className={styles.title}>
                <FormattedMessageSanitized {...messages.teaserTitle} />
              </h2>
              <div className={styles.text}>
                <FormattedMessageSanitized {...messages.teaserContent} />
              </div>
              <div className={styles.actionWrapper}>
                <div className={styles.btnLink}>
                  <QuickstartDemoForm />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(QuickstartDemoTeaser)
