/* eslint-disable max-len */
import { defineMessages } from 'react-intl'

export default defineMessages({
  freshmsValue1: 'Visual and intuitive content editing',
  freshmsValue2: 'Schedule your marketing activities',
  freshmsValue3: 'Preview and edit at one place',
  freshmsValue4: 'Designed for continual adaptation to customer needs and market demands',
  freshmsValue5: 'Sophisticated image management options',
  freshmsValue1a: 'Display dynamic content like images, product data and live prices',
  freshmsValue2a: 'Multichannel marketing',
  freshmsValue3a: 'Real-time API connectivity',
  freshmsValue4a: 'Use dynamic content from any source',
  freshmsValue5a: 'Live prices with option to was price logic',
  freshmsValue1b: 'Maintain multiple content and product sources',
  freshmsValue2b: 'Maintain multiple markets and languages',
  freshmsValue3b: 'Manage all whitelabels of your platform',
  freshmsValue4b: 'Build up whole pages in no time',
  freshmsValue5b: 'Low dependency to the frontend',
  tsboValue1: 'Best practice UX from over a decade of experience ',
  tsboValue2: 'Intuitive & flexible search options',
  tsboValue3: 'Real time prices & availabilities',
  tsboValue4: 'High performance application',
  tsboValue5: 'Top notch usability on all devices',
  tsboValue1a: 'Cuting edge travel platform',
  tsboValue2a: 'Short time 2 market processes',
  tsboValue3a: 'Affordable & conversion optimized foundation',
  tsboValue4a: 'Maximum flexibility to extend your platform over time',
  tsboValue5a: 'Optional features like whitelabeling for multi- language & market',
  tsboValue1b: 'Load-stable system development',
  tsboValue2b: 'Scalable architecture',
  tsboValue3b: 'Years of experience in the leading JavaScript technologies',
  tsboValue4b: 'A/B and multivariance testing',
  tsboValue5b: 'Rule-based whitelabeling from a single code base',
  mightycValue1: 'Communication with decentral content sources',
  mightycValue2: 'Unification of your content from different content sources',
  mightycValue3: 'Central data management and aggregation',
  mightycValue1a: 'Error-tolerant searches for your content',
  mightycValue2a: 'Grouping of content information from different sources',
  mightycValue3a: 'Provide specific content for any app or website centralized & controlled',
  mightycValue1b: 'Management of non-bookable data to make it available, for example, for internet booking engines (IBE)',
  mightycValue2b: 'Content-based page construction, offering explicit SEO advantages',
  mightycValue3b: 'Significant added value in conjunction with player hub technologies like Peakwork’s Player/hub technology',
  fusionValue1: 'Enrich your product portfolio by using multiple providers',
  fusionValue2: 'Be more independent of product providers by seamless switch between mutliple',
  fusionValue3: 'price combativity by matching products of providers and show the cheapest',
  fusionValue4: 'Match, map & merge products attributes',
  fusionValue1a: 'Mix & match products from different providers',
  fusionValue2a: 'Unified structure & interface for products of different providers with custom attribute clustering & matching',
  fusionValue3a: 'Search & filter by all products attributes accross providers',
  fusionValue4a: 'One API for multiple Booking Engine Services',
  fusionValue1b: 'Product source flexible webapp',
  fusionValue2b: 'Fast cache for offers and products',
  fusionValue3b: 'Control how offers are cached for best perfomance',
  fusionValue4b: 'Multi-currency, -language & -market ready',
  tsboquickstartValue1a: 'On premise from landing page to booking confirmation',
  tsboquickstartValue1b: 'Flexible connection to product systems like Peakwork Player-Hub, Amadeus Leisure, Sabre Synxis, Bewotec',
  tsboquickstartValue1c: 'Mapping and consolidation of heterogeneous data structures',
  tsboquickstartValue2a: '100% branding & customisable UX',
  tsboquickstartValue2b: 'All brands and markets as white labels on one sales platform ',
  tsboquickstartValue2c: 'Intuitive frontend editing for your marketing with freshMS®',
  tsboquickstartValue2d: 'Fully individual further development possible',
  tsboquickstartValue3a: 'Low entry barrier – thanks to the platform ecosystem',
  tsboquickstartValue3b: 'Maximum flexibility and comprehensive basic feature set',
  tsboquickstartValue3c: 'Innovative financing model: Leasing possible'
})
